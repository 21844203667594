import { formatCurrency } from "@megarax/react-utils";
import { Link } from "react-router-dom-v5-compat";

import { OrderDto } from "@megaron/crm-contracts";
import { DescriptionField } from "@megaron/dash-description-list";
import { SectionHeader } from "@megaron/dash-page";

type Props = {
  order: OrderDto;
};

export const OrderDetails: React.FC<Props> = ({ order }) => {
  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
      <SectionHeader isHr>Dane zamówienia</SectionHeader>
      <div css={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))", gap: "0.625rem" }}>
        <DescriptionField label="Numer zamówienia">{order.referenceNumber}</DescriptionField>
        <DescriptionField label="Data zamówienia">{order.orderDate}</DescriptionField>
        <DescriptionField label="Kwota netto">
          {formatCurrency(order.netTotal, order.currency ?? "PLN")}
        </DescriptionField>
        <DescriptionField label="Nabywca">
          <Link to={`/crm/customers/id/${order.sellToId}`}>{order.sellToName}</Link>
        </DescriptionField>
        <DescriptionField label="Odbiorca faktury">
          <Link to={`/crm/customers/id/${order.billToId}`}>{order.billToName}</Link>
        </DescriptionField>
        {/* <DescriptionField label="Marża">
          {formatCurrency(order.profitMargin, order.currency ?? undefined)}
        </DescriptionField> */}
      </div>
    </div>
  );
};
