import { AnyUser } from "@megaron/access-control";
import { HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";

import { bi } from "./bi";
import { contacts } from "./contacts";
import { customers } from "./customers";
import { history } from "./history";
import { items } from "./items";
import { margins } from "./margins";
import { purchases } from "./purchases";
import { regions } from "./regions";
import { relations } from "./relation";
import { orderActions } from "./orders";
import { transactionActions } from "./transactions";

export const crmHttpService = HttpService({
  ...customers,
  ...history,
  ...purchases,
  ...regions,
  ...items,
  ...contacts,
  ...bi,
  ...relations,
  ...orderActions,
  ...transactionActions,
  ...margins,
});

export type CrmHandlers = HandlerMap<typeof crmHttpService, AnyUser>;
