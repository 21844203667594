import { PubsubTopic } from "@megaron/pubsub-contracts";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const rewardPurchasedTopic = PubsubTopic({
  name: "loyalty-points-reward-purchased",
  serializer: Serializers.object({
    time: Serializers.datetime,
    purchaseUuid: uuidSerializer,
    accountUuid: uuidSerializer,
    rewardId: Serializers.string,
    price: Serializers.decimal,
    listingUuid: uuidSerializer,
  }),
});
export type RewardPurchasedMessage = SerializerValue<typeof rewardPurchasedTopic.serializer>;

export const proStatusEarnedTopic = PubsubTopic({
  name: "loyalty-points-pro-status-earned",
  serializer: Serializers.object({
    accountUuid: uuidSerializer,
  }),
});
export type ProStatusEarnedMessage = SerializerValue<typeof proStatusEarnedTopic.serializer>;

export const referralBonusEarnedTopic = PubsubTopic({
  name: "loyalty-points-referral-bonus-earned",
  serializer: Serializers.object({
    accountUuid: uuidSerializer,
    referredUserUuid: uuidSerializer,
  }),
});
export type ReferralBonusEarnedMessage = SerializerValue<typeof referralBonusEarnedTopic.serializer>;

export const affiliateCodeClaimedTopic = PubsubTopic({
  name: "loyalty-points-affiliate-code-claimed",
  serializer: Serializers.object({
    codeId: Serializers.string,
    accountUuid: uuidSerializer,
  }),
});
export type AffiliateCodeClaimedMessage = SerializerValue<typeof affiliateCodeClaimedTopic.serializer>;

export const affiliateCodeRedeemedTopic = PubsubTopic({
  name: "loyalty-points-affiliate-code-redeemed",
  serializer: Serializers.object({
    redeemingUserUuid: uuidSerializer,
    affiliateUserUuid: uuidSerializer.nullable(),
    salespersonEmail: Serializers.string.nullable(),
    region: Serializers.string.nullable(),
  }),
});
export type AffiliateCodeRedeemedMessage = SerializerValue<typeof affiliateCodeRedeemedTopic.serializer>;

export const giftPointsGrantedTopic = PubsubTopic({
  name: "loyalty-points-gift-points-granted",
  serializer: Serializers.object({
    accountUuid: uuidSerializer,
    amount: Serializers.decimal,
    time: Serializers.datetime,
  }),
});
export type GiftPointsGrantedMessage = SerializerValue<typeof giftPointsGrantedTopic.serializer>;

export const productCodeRedeemedTopic = PubsubTopic({
  name: "loyalty-points-product-code-redeemed",
  serializer: Serializers.object({
    codeId: Serializers.string,
    accountUuid: uuidSerializer,
    productId: Serializers.string,
    transactionUuid: uuidSerializer,
    massKg: Serializers.decimal,
    redeemedAt: Serializers.datetime,
    basePointValue: Serializers.decimal,
    realPointValue: Serializers.decimal,
  }),
});

export type ProductCodeRedeemedMessage = SerializerValue<typeof productCodeRedeemedTopic.serializer>;

export const loyaltyPointsTopics = [
  rewardPurchasedTopic,
  proStatusEarnedTopic,
  referralBonusEarnedTopic,
  affiliateCodeClaimedTopic,
  affiliateCodeRedeemedTopic,
  giftPointsGrantedTopic,
  productCodeRedeemedTopic,
];
