import { authAttributeSerializer } from "@megaron/access-control";
import { dateStringSerializer } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { PubsubTopic } from "@megaron/pubsub-contracts";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { currencies } from "./currency";
import { transactionDtoSerializer } from "./transactions";

export const orderStatuses = ["awaiting", "cancelled", "inProgress", "suspended", "fulfilled"] as const;

export type OrderStatus = (typeof orderStatuses)[number];

export const orderLineDtoSerializer = Serializers.object({
  id: uuidSerializer,
  lineNumber: Serializers.integer,
  price: Serializers.decimal,
  quantity: Serializers.decimal,
  itemId: Serializers.string,
  itemName: Serializers.string.nullable(),
  productId: Serializers.string.nullable(),
  productGroup: Serializers.string.nullable(),
});

export type OrderLineDto = SerializerValue<typeof orderLineDtoSerializer>;

export const orderDtoSerializer = Serializers.object({
  id: uuidSerializer,
  referenceNumber: Serializers.string,
  type: Serializers.stringOneOf("order", "creditMemo"),
  status: Serializers.stringOneOf(...orderStatuses),
  sellToId: uuidSerializer.nullable(),
  sellToName: Serializers.string.nullable(),
  billToId: uuidSerializer.nullable(),
  billToName: Serializers.string.nullable(),
  orderDate: dateStringSerializer,
  month: Serializers.month,
  createdAt: Serializers.datetime,
  updatedAt: Serializers.datetime,
  lines: orderLineDtoSerializer.array(),
  netTotal: Serializers.decimal,
  currency: Serializers.stringOneOf(...currencies),
  authorizedAttributes: authAttributeSerializer.array(),
  boostedFor: authAttributeSerializer.array(),
  region: Serializers.string.nullable(),
  regionOwnerEmail: Serializers.string.nullable(),
  profit: Serializers.decimal,
});

export type OrderDto = SerializerValue<typeof orderDtoSerializer>;

const saveOrderDtoSerializer = Serializers.object({
  id: uuidSerializer,
  type: Serializers.stringOneOf("order", "creditMemo"),
  referenceNumber: Serializers.string,
  status: Serializers.stringOneOf(...orderStatuses),
  sellToId: uuidSerializer,
  billToId: uuidSerializer,
  lines: Serializers.object({
    id: uuidSerializer,
    lineNumber: Serializers.integer,
    itemId: Serializers.string,
    price: Serializers.decimal,
    quantity: Serializers.decimal,
  }).array(),
  currency: Serializers.stringOneOf(...currencies),
  orderDate: dateStringSerializer,
});

export type SaveOrderDto = SerializerValue<typeof saveOrderDtoSerializer>;

export const orderActions = {
  refreshBcOrders: HttpAction({
    path: "/orders/refresh",
    method: "post",
    requiresAuth: true as const,
  }),
  refreshBcCreditMemos: HttpAction({
    path: "/orders/refreshCreditMemos",
    method: "post",
    requiresAuth: true as const,
  }),
  saveOrder: HttpAction({
    path: "/orders/save",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: saveOrderDtoSerializer,
  }),
  orderQuery: HttpAction({
    path: "/orders/id/:orderId",
    method: "get",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      orderId: uuidSerializer,
    }),
    valueSerializer: orderDtoSerializer,
    errorSerializer: Serializers.stringOneOf("OrderNotFound"),
  }),
  orderBulkQuery: HttpAction({
    path: "/orders/bulkQuery",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      orderId: uuidSerializer.array(),
      sellToId: uuidSerializer.array(),
      billToId: uuidSerializer.array(),
      region: Serializers.string.array(),
    }).partial(),
    valueSerializer: orderDtoSerializer.array(),
  }),
  recalculateOrderTransactions: HttpAction({
    path: "/orders/recalculateTransactions",
    method: "post",
    requiresAuth: true as const,
  }),
  orderTransactionsPreviewQuery: HttpAction({
    path: "/transactions/orderTransactionsPreview",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      lines: Serializers.object({
        itemId: Serializers.string.optional(),
        itemGtin: Serializers.string.optional(),
        price: Serializers.decimal,
        quantity: Serializers.decimal,
      }).array(),
      billToId: uuidSerializer,
      sellToId: uuidSerializer,
    }),
    valueSerializer: Serializers.object({
      transactions: transactionDtoSerializer.nullable().array(),
      totalProfit: Serializers.decimal,
    }),
  }),
};

export const ordersChangedTopic = PubsubTopic({
  name: "crm-orders-changed",
  serializer: Serializers.object({
    /** List of changed orders. `undefined` if all orders were changed */
    orders: Serializers.object({
      id: uuidSerializer,
      sellToId: uuidSerializer.nullable(),
      billToId: uuidSerializer.nullable(),
    })
      .array()
      .optional(),
  }),
});
