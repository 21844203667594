import { useTheme } from "@emotion/react";
import { MdCalendarToday } from "react-icons/md";
import { Link } from "react-router-dom-v5-compat";

import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  scheduleTime: Date;
  customerId: string;
};

export const UpcomingVisitTile: React.FC<Props> = ({ scheduleTime, customerId }) => {
  const theme = useTheme();

  const customerQuery = useClientManager("crm").getCustomer().useQuery({ customerId });

  const visitTime = scheduleTime.toLocaleString().split(" ")[1].split(":").slice(0, 2).join(":");

  return (
    <Link
      to={`/crm/customers/id/${customerId}`}
      css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        background: "white",
        padding: "0.5rem 0.75rem",
        borderRadius: theme.smallBorderRadius,
        border: `1px solid rgba(0, 0, 0, 0.1)`,
        fontSize: "0.875rem",
        flexWrap: "wrap",
        gap: "0.5rem",
        "&:hover": {
          textDecoration: "none",
          span: {
            textDecoration: "underline",
          },
        },
      }}
    >
      <QuerySkeleton query={customerQuery} height="1.25rem" width="8rem">
        {(customer) => <span css={{ color: "black" }}>{customer.name}</span>}
      </QuerySkeleton>
      <div css={{ display: "flex", alignItems: "center", color: theme.colors.primary, gap: "0.5rem" }}>
        <MdCalendarToday size={14} />
        {scheduleTime.toLocaleDateString()} <strong>{visitTime}</strong>
      </div>
    </Link>
  );
};
