import { useParams } from "react-router-dom-v5-compat";

import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page, SectionHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";

import { useRegionProfitMetricsFilters } from "../useRegionProfitMetricsFilters";
import { RegionOrders } from "./RegionOrders";
import { RegionRealizationSection } from "./RegionRealizationSection";

export const RegionRealizationPage = () => {
  const { region } = useParams<{ region: string }>();

  const { isDesktop, isMobile } = useDeviceType();

  const { filters, setFilters } = useRegionProfitMetricsFilters();

  const ordersQuery = useClientManager("docs")
    .searchOrders()
    .useQuery({
      region: region ? [region] : undefined,
      limit: region ? 10 : 1000,
      month: [filters.startDate, filters.endDate],
    });

  if (!region) {
    return <div>Error: Region not provided.</div>;
  }

  return (
    <>
      <HeaderBar header={region} />
      <Page
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "2rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
          marginTop: "83px",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
            gap: "2rem",
            width: "100%",
          }}
        >
          <RegionRealizationSection
            startDate={filters.startDate}
            endDate={filters.endDate}
            region={region}
            onDatesChange={(startDate, endDate) => setFilters({ startDate, endDate })}
          />

          <div css={{ display: "flex", flexDirection: "column", gap: "1rem", minWidth: "400px" }}>
            <SectionHeader isHr css={{ marginTop: 0 }}>
              Zamówienia
            </SectionHeader>
            <QuerySkeleton query={ordersQuery} height="158px" width="100%">
              {(orders) => (
                <RegionOrders
                  orders={region ? orders.items : orders.items.filter((order) => !order.region)}
                  region={region}
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                />
              )}
            </QuerySkeleton>
          </div>
        </div>
      </Page>
    </>
  );
};
