import { useTheme } from "@emotion/react";
import { formatCurrency } from "@megarax/react-utils";
import { Link } from "react-router-dom-v5-compat";

import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";
import { OrderDoc } from "@megaron/docs-contracts";

type Props = {
  orders: OrderDoc[];
  isLoading: boolean;
};

export const OrderDraftsTable: React.FC<Props> = ({ orders, isLoading }) => {
  const theme = useTheme();

  const tableColumns: TableHeadColumn<string>[] = [
    {},
    ...tableHeadingList.map(
      ({ heading }) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (order: OrderDoc): RowCellContent[] => {
    return [
      {
        isLink: true,
        element: (
          <div
            css={{
              position: "absolute",
              inset: 0,
              background: theme.colors.primaryLight,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ),
      },
      {
        isLink: true,
        element: (
          <span css={{ color: theme.colors.primary, textWrap: "nowrap" }}>{order.createdAt.toLocaleDateString()}</span>
        ),
      },
      {
        cellCss: { textWrap: "nowrap" },
        element: (
          <Link css={{ color: theme.colors.primary }} to={`/crm/customers/id/${order.sellToId}`}>
            {order.sellToName}
          </Link>
        ),
      },
      {
        cellCss: { textWrap: "nowrap" },
        element: <Link to={`/crm/customers/id/${order.billToId}`}>{order.billToName}</Link>,
      },

      {
        isLink: true,
        cellCss: { color: theme.colors.primary },
        element: formatCurrency(order.netTotal.toNumber(), order.currency ?? "PLN"),
      },
    ];
  };

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem", padding: "0 2rem" }}>
      <Table columns={tableColumns}>
        <TableBody
          isLoading={isLoading}
          rows={orders.map((order) => ({
            uuid: order.id,
            linkTo: `/crm/order-drafts/${order.id}`,
            cellsContent: getTableRowCellsContent(order),
            css: {
              "&:hover": {
                "td:nth-child(1)": {
                  textDecoration: "underline",
                },
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};

const tableHeadingList: { heading: string }[] = [
  { heading: "Data utw." },
  { heading: "Nabywca" },
  { heading: "Odbiorca faktury" },
  { heading: "Wartość netto" },
];
