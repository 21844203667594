import { AnyUser } from "@megaron/access-control";
import { HttpAction, HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";
import { Serializers } from "@megaron/serializers";

import { anyDocActions, anyDocSerializer } from "./anyDoc";
import { bcVendorActions } from "./docTypes/bcVendor";
import { customerEventActions } from "./docTypes/customerEvents";
import { customerInteractionActions } from "./docTypes/customerInteractions";
import { customerActions } from "./docTypes/customers";
import { invoiceThreadActions } from "./docTypes/invoiceThread";
import { itemActions } from "./docTypes/items";
import { orderActions } from "./docTypes/order";
import { userActions } from "./docTypes/users";
import { indexModuleActions } from "./indexModuleActions";

const addFakeDocs = HttpAction({
  path: "/addFakeDocs",
  method: "put",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({ docs: anyDocSerializer.array() }),
});

const metadataQuery = HttpAction({
  path: "/metadata",
  requiresAuth: true,
  valueSerializer: Serializers.object({
    thisInstanceId: Serializers.string,
    leaderInstanceId: Serializers.string.nullable(),
    leaderLeaseStartTime: Serializers.datetime.nullable(),
    leaderLeaseEndTime: Serializers.datetime.nullable(),
  }),
});

export const docsHttpService = HttpService({
  ...customerActions,
  ...userActions,
  ...anyDocActions,
  ...invoiceThreadActions,
  ...indexModuleActions,
  ...bcVendorActions,
  ...itemActions,
  ...customerEventActions,
  ...customerInteractionActions,
  ...orderActions,
  addFakeDocs,
  metadataQuery,
});

export type DocsHandlers = HandlerMap<typeof docsHttpService, AnyUser>;
