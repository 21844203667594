import { Failure, Ok } from "@megaron/result";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export type Month = string & { __brand: "Month" };

export const isMonth = (str: string): str is Month => str.match(monthRegex) !== null;

export const monthRegex = /^\d{4}-(0[1-9]|1[012])$/;

export const month: Serializer<Month> & SerializerExtensions<Month> = {
  serialize: (month) => {
    if (!monthRegex.test(month)) throw new Error("InvalidMonthFormat");
    return month;
  },
  deserialize: (raw: unknown) => {
    if (typeof raw !== "string") return Failure("NotAString");
    if (!monthRegex.test(raw)) return Failure("InvalidMonthFormat");
    return Ok(raw as Month);
  },
  ...serializerExtensions(),
};

export const Month = (str: string): Month => {
  const result = month.deserialize(str);
  if (result.isFailure) throw new Error("Failed to construct Month string: " + result.error);

  return result.value;
};

export const dateToMonth = (date: Date): Month => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}` as Month;
};
