import { useTheme } from "@emotion/react";
import { Link, useLocation } from "react-router-dom-v5-compat";

import { useDeviceType } from "@megaron/dash-mq";
import { Page, PageHeader, SectionHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";

import { useRegionProfitMetricsFilters } from "../useRegionProfitMetricsFilters";
import { RealizationProgressBar } from "./RealizationProgressBar";
import { TimeRangeSelect } from "./TimeRangeSelect";

export const RegionProfitMetricsPage = () => {
  const { search } = useLocation();

  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const { filters, setFilters } = useRegionProfitMetricsFilters();

  const regionProfitMetricsQuery = useClientManager("crm")
    .regionProfitMetricsQuery()
    .useQuery({ months: [filters.startDate, filters.endDate] });

  return (
    <Page
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginBottom: "2rem",
      }}
    >
      <PageHeader>Realizacja planu marży</PageHeader>
      <div css={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr", gap: "2rem" }}>
        <div css={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <TimeRangeSelect
            startDate={filters.startDate}
            endDate={filters.endDate}
            onDatesChange={(startDate, endDate) => setFilters({ startDate, endDate })}
          />
          <QuerySkeleton query={regionProfitMetricsQuery}>
            {(realization) => (
              <RealizationProgressBar
                label="Razem"
                currentRealization={realization.profit.toNumber()}
                goalRealization={realization.target.toNumber()}
              />
            )}
          </QuerySkeleton>
          <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <SectionHeader isHr css={{ marginTop: 0 }}>
              Makroregiony
            </SectionHeader>
            <QuerySkeleton query={regionProfitMetricsQuery}>
              {(realization) => (
                <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
                  {realization.byMacroregion.map(({ macroregion, profit, target }) => (
                    <div
                      key={`region-margin-realization-${macroregion ?? "brak"}`}
                      css={{
                        borderRadius: theme.smallBorderRadius,
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        padding: "1rem",
                        background: "white",
                      }}
                    >
                      <RealizationProgressBar
                        label={macroregion ?? "Brak nazwy"}
                        currentRealization={profit.toNumber()}
                        goalRealization={target.toNumber()}
                      />
                    </div>
                  ))}
                </div>
              )}
            </QuerySkeleton>
          </div>
        </div>
        <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <SectionHeader isHr css={{ marginTop: 0 }}>
            Regiony
          </SectionHeader>
          <QuerySkeleton query={regionProfitMetricsQuery}>
            {(realization) => (
              <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
                {realization.byRegion.map(({ region, profit, target, regionOwner }) => (
                  <Link
                    to={`/crm/region-profit-metrics/${region ?? "brak"}${search}`}
                    key={`region-margin-realization-${region ?? "brak"}`}
                    css={{
                      borderRadius: theme.smallBorderRadius,
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      padding: "1rem",
                      background: "white",
                    }}
                  >
                    <RealizationProgressBar
                      label={region ?? "Brak nazwy"}
                      regionOwnerEmail={regionOwner}
                      currentRealization={profit.toNumber()}
                      goalRealization={target.toNumber()}
                    />
                  </Link>
                ))}
              </div>
            )}
          </QuerySkeleton>
        </div>
      </div>
    </Page>
  );
};
