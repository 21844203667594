import React from "react";
import { BsStars } from "react-icons/bs";
import { MdCreate, MdOutlineDelete } from "react-icons/md";
import { RiMedal2Line } from "react-icons/ri";

import { EventType } from "@megaron/crm-contracts";
import { BaseEntry } from "@megaron/dash-history";
import { CustomerEventDoc } from "@megaron/docs-contracts";
import { LoyaltyAdminLogo } from "@megaron/megarax-links";

import { formattedDateString } from "./InteractionTile";

type Props = {
  event: CustomerEventDoc;
  invitedUsers?: Record<string, { firstName: string | null; lastName: string | null; phoneNumber: string }>;
};

export const EventTile: React.FC<Props> = (props) => {
  const additionalText = (event: CustomerEventDoc): string | undefined => {
    switch (event.payload.type) {
      case "rewardPurchased":
        return event.payload.rewardId;
      default:
        return undefined;
    }
  };

  const titleAdditionalText = additionalText(props.event);

  return (
    <BaseEntry
      icon={getIconByEventType(props.event.payload.type)}
      title={`${getMessageByEventType(props.event.payload.type)}${
        titleAdditionalText ? `: ${titleAdditionalText}` : ""
      }`}
      time={formattedDateString(props.event.payload.time)}
    />
  );
};

const getMessageByEventType = (eventType: EventType): string => {
  switch (eventType) {
    case "userJoined":
      return "Klient dołączył do programu";
    case "proStatusEarned":
      return "Klient uzyskał status Pro";
    case "rewardPurchased":
      return "Klient odebrał nagrodę";
    case "affiliateCodeClaimed":
      return "Klient uzyskał status Partnera";
    case "affiliateCodeRedeemed":
      return "Klient zeskanował zaproszenie";
    case "userRegistered":
      return "Klient dokończył rejestracje";
    case "accountDeleted":
      return "Klient usunął konto";
    case "productCodeRedeemed":
      return "Klient zrealizował kod produktu";
    default:
      return "Nieznany typ zdarzenia";
  }
};

const getIconByEventType = (eventType: EventType): React.ReactNode => {
  switch (eventType) {
    case "userJoined":
      return (
        <LoyaltyAdminLogo
          css={{
            height: "16px",
            width: "16px",
          }}
        />
      );
    case "proStatusEarned":
      return <BsStars />;
    case "rewardPurchased":
      return <RiMedal2Line />;
    case "affiliateCodeClaimed":
      return <RiMedal2Line />;
    case "affiliateCodeRedeemed":
      return <RiMedal2Line />;
    case "userRegistered":
      return <MdCreate />;
    case "accountDeleted":
      return <MdOutlineDelete />;
    case "productCodeRedeemed":
      return (
        <LoyaltyAdminLogo
          css={{
            height: "16px",
            width: "16px",
          }}
        />
      );
    default:
      return null;
  }
};
